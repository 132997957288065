import { ShopImprintButton } from "components/restaurants/RestaurantImprintButton"
import { getAuth, signOut } from "firebase/auth"
import { useShop } from "hooks/firestore/simple/useShop"
import { useUser } from "hooks/firestore/simple/useUser"
import { useCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import gastromiaLogo from "images/gastromia_logo.png"
import Image from "next/image"
import styled from "styled-components"

interface FooterProps {
  hideMobile?: boolean
  hideDesktop?: boolean
}

const Footer = styled.div<FooterProps>`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: calc((env(safe-area-inset-bottom, 20px) * 1.5) + 2rem);
  padding-bottom: 70px;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    display: ${props => (props.hideMobile ? "none" : "flex")};
  }

  @media (min-width: ${props => props.theme.breakpoints.large}) {
    display: ${props => (props.hideDesktop ? "none" : "flex")};
  }
`

interface SideFooterProps {
  hideMobile?: boolean
  hideDesktop?: boolean
}

const SideFooter = ({ hideMobile, hideDesktop }: SideFooterProps) => {
  const user = useCurrentUser()
  const getpackedUser = useUser()
  const shop = useShop()

  return (
    <Footer hideMobile={hideMobile} hideDesktop={hideDesktop}>
      Powered by{" "}
      <a href="https://gastromia.de/" target="_blank" rel="noreferrer">
        <Image width={150} height={50} src={gastromiaLogo} alt="GASTROMIA Logo" className="tw-object-contain" />
      </a>
      <br />
      <div>
        <ShopImprintButton />
        {" | "}
        {shop?.linkedInfos?.terms && (
          <>
            <a href={shop?.linkedInfos.terms} target="_blank" rel="noreferrer">
              AGB
            </a>
            {" | "}
          </>
        )}
        {shop?.linkedInfos?.dataPrivacy && (
          <>
            <a href={shop?.linkedInfos.dataPrivacy} target="_blank" rel="noreferrer">
              Datenschutzerklärung
            </a>
            {" | "}
          </>
        )}
        {" | "}
        <a href="/legal/datenschutz/" target="_blank" rel="noreferrer">
          Datenschutz
        </a>
        {" | "}
        {user ? (
          <>
            {" | "}
            <a href={"#"} onClick={() => signOut(getAuth())}>
              Logout
            </a>
          </>
        ) : null}
        {shop && getpackedUser?.getpackedAdmin ? (
          <>
            {" | "}
            <a href={`/admin/load-shop/${shop._ref.id}?page=orders`}>Shop als Admin bearbeiten</a>
          </>
        ) : null}
      </div>
    </Footer>
  )
}

export default SideFooter
